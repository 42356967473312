import Link from 'next/link'
import React from 'react'

const ConditionalLink = ({ href = null, children }) => {
  return (
    <>
      {href ? (
        <Link href={href} legacyBehavior>
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  )
}

export default ConditionalLink
