import { motion } from 'framer-motion'
import React from 'react'

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

const Switch = ({
  enabled,
  onClick = null,
  handleColor = 'bg-backgroundContrast',
  handleLabel = '',
  handleLabelColor = 'text-background',
  disabled = false,
}) => {
  const _onClick = () => {
    if (disabled) return
    if (onClick) onClick()
  }

  return (
    <motion.div
      className={`form-switch flex relative ${
        enabled ? 'form-switch-active bg-primary' : 'bg-gray-500 bg-opacity-10 hover:bg-opacity-20'
      } ${
        disabled ? 'form-switch-disabled opacity-60' : ''
      } flex-shrink-0 transition-colors ease-in-out items-center h-[20px] w-[44px] rounded-full cursor-pointer`}
      data-enabled={enabled}
      onClick={_onClick}
      layout
      layoutRoot
    >
      <motion.div
        className={`form-switch-handle absolute h-[22px] w-[22px] aspect-square ${
          enabled ? 'form-switch-handle-enabled right-0' : 'left-0'
        } ${handleColor} ${handleLabelColor} transition-colors ease-in-out rounded-full text-xxs font-semibold flex justify-center items-center`}
        layout
        transition={spring}
      >
        {handleLabel}
      </motion.div>
    </motion.div>
  )
}

export default Switch
