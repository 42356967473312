import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { deleteUserAvatar } from '../../../lib/account'
import { useAccountClient } from '../../../lib/api-client'
import { useUser } from '../../../lib/user'
import { getFirstOrDefault } from '../../../util/array'
import { emptyGuid } from '../../../util/guid'
import { FileValidator } from '../../../util/validators'
import { OutlineButton } from '../../core/Button'
import Modal from '../../core/Modal'
import { FormDivider } from '../../forms/elements/Divider'
import { FormFieldConfig, FormFieldType, FormBuilder } from '../../forms/FormBuilder'

const AvatarUploadModal = ({ sessionId, showUploadModal, setShowUploadModal, onAvatarChange }) => {
  const accountClient = useAccountClient()
  const { user } = useUser()
  const { t, lang } = useTranslation('account')

  const [formFields, setFormFields] = useState([])

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true,
  })

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    initializeForm()
  }, [lang, showUploadModal])

  const initializeForm = () => {
    setErrorMessage(null)
    setSuccessMessage(null)
    setFormFields(buildFormFields())
  }

  const buildFormFields = () => {
    const avatar: FormFieldConfig = {
      name: t('AvatarModal.upload.file', { maxFileSize: 5 }),
      property: 'avatar',
      formFieldType: FormFieldType.Input,
      type: 'file',
      accept: '.jpg,.jpeg,.png',
      required: true,
      validators: FileValidator(t, 5 * Math.pow(2, 20), ['image/jpeg', 'image/png']),
    }
    return [avatar]
  }

  const onSubmit = async (formData) => {
    setSuccessMessage(null)
    setErrorMessage(null)

    const data = new FormData()
    data.append('image', getFirstOrDefault(formData.avatar))

    try {
      const res = await accountClient.uploadUserAvatar({ sessionId }, data)
      if (res != null) {
        setSuccessMessage(t('AvatarModal.upload.successMessage'))
        onAvatarChange()
        reset()
        setFormFields([])
      } else {
        setErrorMessage(t('AvatarModal.upload.errorMessage'))
      }
    } catch (error) {
      setErrorMessage(t('AvatarModal.upload.errorMessage'))
    }
  }

  const onDeleteAvatar = async () => {
    const res = await deleteUserAvatar()
    if (res) {
      setSuccessMessage(t('AvatarModal.delete.successMessage'))
      onAvatarChange()
      reset()
      setFormFields(buildFormFields())
    } else {
      setErrorMessage(t('AvatarModal.delete.errorMessage'))
    }
  }

  return (
    <>
      <Modal title={t('AvatarModal.title')} show={showUploadModal} closable onClose={() => setShowUploadModal(false)}>
        <FormBuilder
          _fields={formFields}
          _register={register}
          _handleSubmit={handleSubmit}
          _onSubmit={formFields.length > 0 ? onSubmit : null}
          _submitText={t('AvatarModal.upload.submit')}
          _isValid={isValid}
          _errors={errors}
          _errorMessage={errorMessage}
          _successMessage={successMessage}
        />

        {user?.profile['avatarId'] != emptyGuid && (
          <>
            <FormDivider />
            <OutlineButton
              rounded
              fullWidth
              className="mt-2 !border-[1px] border-red-500 text-red-500"
              onClick={onDeleteAvatar}
            >
              {t('AvatarModal.delete.submit')}
            </OutlineButton>
          </>
        )}
      </Modal>
    </>
  )
}

export default AvatarUploadModal
