import { faPlus, faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useUser } from '../../lib/user'
import { getCdnImageUrl } from '../../util/cdn'
import Image from 'next/legacy/image'
import AvatarUploadModal from '../account/modals/AvatarUploadModal'

const UserAvatar = ({ height = '64px', width = '64px', className = '', isEditable = false, sessionId = null }) => {
  const { user, mutateUser } = useUser()
  const avatarUrl = getCdnImageUrl(user?.profile ? user.profile['avatarId'] : null)

  const [showAvatarModal, setShowAvatarModal] = useState(false)

  const onAvatarChange = () => {
    mutateUser()
  }

  return (
    <>
      <div
        className={
          'user-avatar flex justify-center items-center flex-shrink-0 rounded-full relative cursor-pointer ' + className
        }
        onClick={isEditable ? () => setShowAvatarModal(true) : null}
      >
        {avatarUrl ? (
          <div
            className="user-avatar-img-wrapper flex justify-center items-center rounded-full overflow-hidden relative"
            style={{ height, width }}
          >
            <Image
              src={avatarUrl}
              alt="avatar"
              className="user-avatar-img rounded-full transition"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={avatarUrl}
            />
          </div>
        ) : (
          <div
            className="user-avatar-placeholder flex justify-center items-center rounded-full bg-primary"
            style={{ height, width }}
          >
            <FontAwesomeIcon className="user-avatar-placeholder-icon text-2xl text-primaryContrast" icon={faUser} />
          </div>
        )}

        {isEditable && (
          <div className="user-avatar-edit-indicator absolute right-0 bottom-0 bg-background text-primary rounded-full w-6 h-6 flex justify-center items-center shadow-md text-xs transition hover:bg-primary hover:text-background">
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </div>

      {isEditable && (
        <AvatarUploadModal
          sessionId={sessionId}
          showUploadModal={showAvatarModal}
          setShowUploadModal={setShowAvatarModal}
          onAvatarChange={onAvatarChange}
        />
      )}
    </>
  )
}

export default UserAvatar
