import { faCheck, faInfoCircle, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence } from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { setBodyNotScrollable, setBodyScrollable } from '../../util/toggleBodyScrollable'
import { ContainedButton, OutlineButton } from './Button'
import OverlayContainer from './OverlayContainer'

type ConfirmationModalProps = BaseModalProps & {
  onConfirm?: (...args) => any
  onCancel?: (...args: any) => any
  confirmText?: string
  cancelText?: string
}
export const ConfirmationModal = ({
  title,
  description,
  onClose,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
}: ConfirmationModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal show={true} closable onClose={onClose}>
      <div className="confirmation-modal flex flex-col justify-center">
        <h1 className="confirmation-modal-title text-lg font-semibold mb-4">{title}</h1>

        <p className="confirmation-modal-description mb-4">{description}</p>

        <div className="confirmation-modal-actions flex justify-center space-x-2 w-full mt-8">
          <OutlineButton
            borderColor="border-transparent"
            className="confirmation-modal-action-confirm opacity-50"
            onClick={onConfirm}
          >
            {confirmText ? confirmText : t('common:Common.ok')}
          </OutlineButton>

          <ContainedButton
            borderColor="border-primary"
            className="confirmation-modal-action-cancel"
            onClick={onCancel}
            rounded
          >
            {cancelText ? cancelText : t('common:Common.cancel')}
          </ContainedButton>
        </div>
      </div>
    </Modal>
  )
}

export const InfoModal = ({ title, description, onClose }: BaseModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal show={true} closable onClose={onClose}>
      <div className="info-modal flex flex-col justify-center">
        <h1 className="info-modal-title text-lg font-semibold mb-4 text-center">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-blue-500" />
          {title}
        </h1>
        <p className="info-modal-description mb-4">{description}</p>
        <ContainedButton onClick={onClose}>{t('common:Common.ok')}</ContainedButton>
      </div>
    </Modal>
  )
}

export type BaseModalProps = {
  title: string
  description: string
  onClose: (...args: any) => any
}

export const SuccessModal = ({ title, description, onClose }: BaseModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal show={true} closable onClose={onClose}>
      <div className="success-modal flex flex-col justify-center">
        <h1 className="success-modal-title text-lg font-semibold mb-4 text-center">
          <FontAwesomeIcon icon={faCheck} className="mr-2 text-green-500" />
          {title}
        </h1>
        <p className="success-modal-description mb-4">{description}</p>
        <ContainedButton onClick={onClose}>{t('common:Common.ok')}</ContainedButton>
      </div>
    </Modal>
  )
}

export type CustomModalProps = {
  show: boolean
  title?: string
  closable?: boolean
  onClose?: (...args: any) => any
  children: ReactNode
  wrapperClassName?: string
  contentClassName?: string
}

const Modal = ({
  show,
  closable = false,
  onClose = null,
  children,
  title = null,
  wrapperClassName = '',
  contentClassName = '',
}: CustomModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    if (show) {
      document.addEventListener('click', handleClickOutside, true)
      setBodyNotScrollable()
    } else {
      document.removeEventListener('click', handleClickOutside, true)
      setBodyScrollable()
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      setBodyScrollable()
    }
  }, [show])

  const handleCloseClick = (e) => {
    if (!closable || onClose == null) return
    e.preventDefault()
    onClose()
  }

  const handleClickOutside = (event) => {
    if (closable && ref.current && !ref.current.contains(event.target)) {
      onClose()
    }
  }

  const modalContent = (
    <AnimatePresence>
      {show && (
        <OverlayContainer onBackgroundClick={handleCloseClick}>
          <div
            ref={ref}
            className={`modal flex flex-col relative overflow-auto bg-background text-backgroundContrast rounded-lg p-8 ${
              closable ? 'pt-12' : ''
            } max-h-[90vh] max-w-[90vw] drop-shadow-2xl z-30 ${wrapperClassName}`}
          >
            {closable && (
              <div
                className="modal-close absolute top-0 right-0 p-4 leading-[0] cursor-pointer"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
            {title && <h1 className="modal-title text-lg font-semibold mb-4">{title}</h1>}
            <div className={`modal-content ${contentClassName}`}>{children}</div>
          </div>
        </OverlayContainer>
      )}
    </AnimatePresence>
  )

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById('modal-root')) as JSX.Element
  } else {
    return null
  }
}

export default Modal
