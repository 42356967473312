import { useState, useEffect, useRef } from 'react'

export type ScrollDirection = 'up' | 'down'

const useScrollDirection = (scrollOffsetThreshold: number = 0) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)
  const [scrollOffset, setScrollOffset] = useState<number>(0)
  const scrollOffsetRef = useRef(scrollOffset)

  useEffect(() => {
    scrollOffsetRef.current = scrollOffset
  }, [scrollOffset])

  useEffect(() => {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 'down' : 'up'

      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction)
      }

      lastScrollY = scrollY > 0 ? scrollY : 0

      if (
        (scrollOffsetRef.current < scrollOffsetThreshold && scrollY > scrollOffsetThreshold) ||
        (scrollOffsetRef.current > scrollOffsetThreshold && scrollY < scrollOffsetThreshold)
      ) {
        setScrollOffset(scrollY)
      }
    }
    window.addEventListener('scroll', updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  }, [scrollDirection])

  return [scrollDirection, scrollOffset]
}

export default useScrollDirection
