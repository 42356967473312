import { motion } from 'framer-motion'
import { useFrontendResources } from '../../lib/content'
import Image from "next/legacy/image"
import { getMinIoImageUrl } from '../../util/cdn'
import { getValueOfCssVariable, isColorLightOrDark } from '../../util/color'
import { useEffect, useState } from 'react'

export type LogoProps = {
  height?: string
  className?: string
  animate?: boolean
  appearance?: 'light' | 'dark'
  backgroundColorVariableName?: string
  onClick?: () => void
}

const Logo = ({
  height = '42px',
  className = '',
  animate = true,
  appearance = null,
  backgroundColorVariableName = null,
  onClick = null,
}: LogoProps) => {
  const frontendResources = useFrontendResources()
  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    if (frontendResources) {
      const fileGuid = getLogoBasedOnBackgroundColor()?.fileGuid
      setImageUrl(fileGuid ? getMinIoImageUrl(fileGuid) : null)
    }
  }, [frontendResources, backgroundColorVariableName])

  const getLogoBasedOnBackgroundColor = (): any => {
    const logoLight = frontendResources?.logos?.mainLogo
    const logoDark = frontendResources?.logos?.mainLogoContrast

    if (appearance === 'light' && logoLight) return logoLight
    if (appearance === 'dark' && logoDark) return logoDark

    const bgColor = backgroundColorVariableName
      ? getValueOfCssVariable(backgroundColorVariableName)
      : getValueOfCssVariable('--background')

    if (logoDark == null) return logoLight
    if (logoLight == null) return logoDark

    return isColorLightOrDark(bgColor) === 'light' ? logoDark : logoLight
  }

  return (
    <motion.div
      className={`logo flex items-center justify-center relative w-full ${className}`}
      style={{ height }}
      layoutId={animate ? 'logo' : null}
      onClick={onClick ?? null}
    >
      {imageUrl && <img src={imageUrl} className="logo-img h-full" alt="logo" />}
    </motion.div>
  )
}

export default Logo
